<template>
  <v-row>
    <v-col :cols="8">
      <v-card class="pt-5">
        <v-card-title class="d-flex justify-space-between">
          <div>
            #{{ order.id }} - {{ order.order_number }}
            <nature-icon
              class="ml-2"
              :nature="order.nature"
            >
            </nature-icon>
          </div>
          <div>
            <template v-if="order.refund_total > 0">
              <v-chip
                v-if="order.refund_total === order.total"
                color="warning"
              >
                FULLY REFUNDED
              </v-chip>
              <v-chip
                v-else
                color="warning"
              >
                PARTIALLY REFUNDED
              </v-chip>
            </template>
            <v-chip v-else>
              {{ order.status }}
            </v-chip>
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            Nature:
            <v-chip>{{ order.nature }}</v-chip>
            <v-chip
              v-for="note in notes"
              :key="note.id"
              class="ml-2"
            >
              {{ t(note.name) }}
            </v-chip>
          </p>
          <p>Total: <b>{{ order.total | price }}</b></p>
          <p>Refund Total: {{ order.refund_total | price }}</p>
          <p>Discount Total: {{ order.discount_total | price }}</p>
          <p>Shipping Total: {{ order.shipping_total | price }}</p>
          <p>Items Count: {{ order.items.length }}</p>
          <p>Created time: {{ order.created_at | date }}</p>
          <p v-if="address">
            Address: <b>{{ address }}</b>
          </p>
          <p v-if="remarks">
            Remarks: <b>{{ remarks }}</b>
          </p>
          <div>
            <a @click="showLogs = true">Show Logs</a>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-show="showCheckout"
      :cols="4"
    >
      <v-card class="pt-5 mb-5">
        <v-card-title>
          <v-icon class="mr-2">
            {{ icons.mdiAccountCircle }}
          </v-icon>
          <v-chip
            v-if="order.user.grade"
            class="mr-2"
            color="warning"
          >
            {{ order.user.grade }}
          </v-chip>
          User Info
        </v-card-title>
        <v-card-text>
          <div class="mb-3">
            <span
              class="font-weight-medium"
              :to="{ name: 'channel-user-view', params: { id: order.user_id } }"
            >
              {{ order.user.email }}
            </span>
          </div>
          <p>user id:{{ order.user.channel_user_id }}</p>
          <p>staff id:{{ order.user.employee_id }}</p>
          <template v-if="receipt">
            <h4>(Below info from Receipt)</h4>
            <p>legal name: {{ receipt.legal_name }}</p>
            <p>phone: {{ receipt.phone || 'n/a' }}</p>
            <p>email: {{ receipt.email }}</p>
          </template>
        </v-card-text>
      </v-card>

      <v-card class="pt-5 mb-5">
        <v-card-title>
          <v-icon>{{ icons.mdiCreditCard }}</v-icon>
          Checkout & Payment:
        </v-card-title>
        <v-card-text>
          <h4>{{ order.checkout.receipt_number }}</h4>

          <!-- <router-link :to="{ name: 'checkout-receipt-preview', params: { id: order.checkout.id } }">
            <v-icon size="18">
              {{ icons.mdiReceipt }}
            </v-icon>
            Show Receipt
          </router-link> -->

          <v-divider class="mt-5 mb-5" />
          <p>Payment Total: {{ order.checkout.total | price }}</p>
          <p>Credit Total: {{ order.checkout.credits_total | price }}</p>
          <p>Refund Total: {{ order.checkout.refund_total | price }}</p>
          <p v-if="order.checkout.custom_id">
            Custom ID: {{ order.checkout.custom_id }}
          </p>
          <p v-if="order.checkout.coupon_code">
            Coupon code: {{ order.checkout.coupon_code }}
          </p>
          <!--
          <v-btn
            color="warning"
            class="mt-3"
            @click="requestCheckoutId = order.checkout_id"
          >
            Request Refund
          </v-btn>

          <v-btn
            color="info"
            class="mt-3"
            @click="resendCheckoutId = order.checkout_id"
          >
            Resend to...
          </v-btn> -->
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="showLogs">
      <order-logs-list
        ref="shipmentForm"
        :order="order"
        :ready="showLogs"
        @close="showLogs = false"
      ></order-logs-list>
    </v-dialog>

    <!-- <refund-request-dialog
      v-if="requestCheckoutId"
      :show="true"
      :checkout-id="requestCheckoutId"
      @close="requestCheckoutId = null"
      @created="$emit('updated')"
    >
    </refund-request-dialog>

    <resend-notify-checkout-dialog
      v-if="resendCheckoutId"
      :show="true"
      :checkout-id="resendCheckoutId"
      :default-email="receiptEmail || order.user.email"
      @close="resendCheckoutId = null"
      @created="$emit('updated')"
    >
    </resend-notify-checkout-dialog> -->
  </v-row>
</template>
<script>
import NatureIcon from '@/components/checkout/NatureIcon.vue'
import { t } from '@/plugins/i18n'
import { date, price } from '@core/utils/filter'
import { mdiAccountCircle, mdiCreditCard, mdiReceipt } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import uniqBy from 'lodash/uniqBy'
import OrderLogsList from './OrderLogsList.vue'

// import RefundRequestDialog from '../../refund-request-resource/RefundRequestDialog.vue'
// import ResendNotifyCheckoutDialog from '../../checkout-resource/ResendNotifyCheckoutDialog.vue'

export default {
  filters: { price, date },

  components: {
    OrderLogsList,

    // RefundRequestDialog,
    // ResendNotifyCheckoutDialog,
    NatureIcon,
  },

  props: {
    order: { type: Object, required: true },
    showCheckout: { type: Boolean, default: false },
    receiptEmail: { type: String, default: '' },
    receipt: { type: [Object, Boolean], default: false },
  },

  setup(props) {
    const requestCheckoutId = ref(false)
    const resendCheckoutId = ref(false)
    const show = ref(false)

    const notes = computed(() => {
      let cnotes = []
      props.order.items.forEach(item => {
        cnotes = cnotes.concat(item.product.channel_notes || [])
      })

      return uniqBy(cnotes, 'id')
    })

    const address = computed(() => props.order.shippings[0]?.address || '')
    const remarks = computed(() => props.order.shippings[0]?.destination.remarks || '')

    return {
      t,
      showLogs: ref(false),
      address,
      remarks,

      requestCheckoutId,
      resendCheckoutId,
      show,

      notes,

      icons: {
        mdiReceipt,
        mdiAccountCircle,
        mdiCreditCard,
      },
    }
  },
}
</script>
