import axios from '@axios'

const fetchOrders = queryParams => axios.get('/merchant/orders', { params: queryParams })

const fetchOrder = id => axios.get(`/merchant/orders/${id}`)

const fetchOrderCheckouts = queryParams => axios.get('/merchant/order-checkouts', { params: queryParams })

const fetchOrderCheckout = id => axios.get(`/merchant/order-checkouts/${id}`)

const fetchCheckoutReceipts = id => axios.get(`/merchant/order-checkouts/${id}/receipts`)

const downloadCheckoutReceiptPDF = id => axios.get(`/merchant/order-checkouts/${id}/receipts/download.pdf`, {
  responseType: 'blob',
})

const confirmOrderItems = (id, ids) => axios.post(`/merchant/orders/${id}/confirm`, { order_item_ids: ids })

const cancelOrder = (id, ids) => axios.delete(`/merchant/orders/${id}/cancel`, { params: { order_item_ids: ids } })

const completeOrder = (id, ids) => axios.post(`/merchant/orders/${id}/complete`, { order_item_ids: ids })

const readyForPickupOrder = (id, ids) => axios.post(`/merchant/orders/${id}/ready-for-pickup`, { order_item_ids: ids })

const completePickupOrder = (id, ids) => axios.post(`/merchant/orders/${id}/complete-pickup`, { order_item_ids: ids })

const fetchRefundRequests = queryParams => axios.get('/merchant/refund-requests', { params: queryParams })

const fetchRefundRequest = id => axios.get(`/merchant/refund-requests/${id}`)

const storeRefundRequest = data => axios.post('/merchant/refund-requests', data)

const acceptRefundRequest = id => axios.post(`/merchant/refund-requests/${id}/accept`)

const rejectRefundRequest = id => axios.post(`/merchant/refund-requests/${id}/reject`)

export {
    fetchOrders,
    fetchOrder,
    fetchOrderCheckouts,
    fetchOrderCheckout,
    fetchCheckoutReceipts,
    downloadCheckoutReceiptPDF,

    confirmOrderItems,
    cancelOrder,
    completeOrder,
    readyForPickupOrder,
    completePickupOrder,

    fetchRefundRequests,
    fetchRefundRequest,
    storeRefundRequest,
    acceptRefundRequest,
    rejectRefundRequest,
}

