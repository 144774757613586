<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>
        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="orderData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <refund-request-items
              :items="requestItems"
              class="mb-5"
            />
            <order-overview
              :order="orderData"
              :show-checkout="true"
              :receipt="orderData.checkout.receipts[0]"
              @updated="reloadOrder"
            />
          </v-tab-item>
          <v-tab-item>
            <order-items-list
              :order="orderData"
              @updated="reloadOrder"
              @openShipmentDialog="openShipmentDialog"
            />
          </v-tab-item>
          <v-tab-item>
            <order-nature-details :order="orderData" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="showShipmentFormDialog"
        persistent
        max-width="800px"
      >
        <shipment-form
          ref="shipmentForm"
          :order="orderToShip"
          @updated="reloadOrder"
          @close="showShipmentFormDialog = false"
        ></shipment-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ShipmentForm from '@/components/checkout/ShipmentForm.vue'
import router from '@/router'
import { mdiAccountMinusOutline, mdiAlphaOBox, mdiCash100, mdiInboxMultiple, mdiTruck } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import RefundRequestItems from '../refund-request-resource/RefundRequestItems.vue'
import OrderItemsList from './order-overview/OrderItemsList.vue'
import OrderNatureDetails from './order-overview/OrderNatureDetails.vue'
import OrderOverview from './order-overview/OrderOverview.vue'
import useOrderView from './useOrderView'

export default {
  components: {
    ShipmentForm,
    OrderOverview,
    OrderItemsList,
    OrderNatureDetails,
    RefundRequestItems,
  },
  setup() {
    const { loadOrder, orderData } = useOrderView()

    const reloadOrder = () => {
      orderData.value = null

      return loadOrder(router.currentRoute.params.id).then(() => {
        // loadCheckout(orderData.value.checkout.id)
      })
    }

    // UnRegister on leave

    const tabs = computed(() => {
      const options = [
        { icon: mdiAlphaOBox, title: 'OVERVIEW' },
        { icon: mdiInboxMultiple, title: 'ITEMS' },
      ]

      let nature = ''
      if (orderData.value) {
        nature = orderData.value.nature
      }

      if (!['tangible'].includes(nature)) {
        options.push({ icon: mdiAccountMinusOutline, title: 'PARTICIPANTS' })
      } else {
        options.push({ icon: mdiTruck, title: 'SHIPPINGS' })
      } 
      if (nature === 'voucher') {
        options.push({ icon: mdiCash100, title: 'VOUCHERS' })
      }

      return options
    })
    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    // Shipment Formn Dialog
    const showShipmentFormDialog = ref(false)
    const orderToShip = ref(null)

    const openShipmentDialog = () => {
      orderToShip.value = orderData.value
      showShipmentFormDialog.value = true
    }

    // const requestItems = computed(() => {
    //   if (!checkoutData.value) {
    //     return []
    //   }
    //   let items = []
    //   checkoutData.value.payments.forEach(payment => {
    //     items = items.concat(payment.refund_requests)
    //   })

    //   return items
    // })

    const requestItems = []

    onMounted(() => reloadOrder().then(switchToDefaultTab))

    return {
      tabs,
      orderData,
      reloadOrder,

      requestItems,
      openShipmentDialog,

      showShipmentFormDialog,
      orderToShip,

      currentTab,
    }
  },
}
</script>
