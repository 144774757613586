<template>
  <v-icon class="ml-2">
    <template v-if="nature === 'tangible'">
      {{ icons.mdiTruck }}
    </template>
    <template v-else-if="nature === 'service'">
      {{ icons.mdiMeditation }}
    </template>
    <template v-else-if="nature === 'voucher'">
      {{ icons.mdiCash100 }}
    </template>
  </v-icon>
</template>

<script>
import { mdiTruck, mdiMeditation, mdiCash100 } from '@mdi/js'

export default {
  props: {
    nature: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      icons: {
        mdiTruck,
        mdiMeditation,
        mdiCash100,
      },
    }
  },
}
</script>
