<template>
  <div class="mb-7">
    <v-container
      v-if="order.shippings.length"
      fluid
    >
      <v-card
        v-for="shipping in order.shippings"
        :key="shipping.id"
        class="mx-auto"
        outlined
      >
        <v-card-title>Shipping Destination</v-card-title>
        <v-card-text>
          <v-chip>status: {{ shipping.status }}</v-chip>
          <p>Address: {{ shipping.address }}</p>
          <p>Remarks: {{ shipping.destination.remarks }}</p>
          <p>Recipient: {{ shipping.destination.first_name }}, {{ shipping.destination.last_name }}</p>
          <p>Contact Number: {{ shipping.destination.contact_number }}</p>
          <p>Fee Payment: {{ shipping.fee_payment }}</p>
        </v-card-text>
      </v-card>
    </v-container>

    <h2>Participants</h2>

    <div
      v-for="item in order.items"
      :key="item.uuid"
    >
      <template v-if="item.vouchers.length">
        <v-container
          v-for="(voucher, index) in item.vouchers"
          :key="voucher.code"
          fluid
        >
          <v-card
            class="mx-auto"
            outlined
          >
            <v-card-title>Voucher: # {{ index + 1 }}</v-card-title>
            <v-card-text>
              <v-chip>status: {{ voucher.status }}</v-chip>
              <p>code: {{ voucher.code }}</p>
            </v-card-text>
          </v-card>
        </v-container>
      </template>

      <v-container
        v-if="item.participants.length"
        fluid
      >
        <h3>{{ t(item.sku_snapshot.sku_name) }}</h3>
        <v-row dense>
          <v-col
            v-for="(participant,index) in item.participants"
            :key="participant.id"
            cols="6"
          >
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card-title>Participant: # {{ index + 1 }}</v-card-title>
              <v-card-text>
                <p>Name: {{ participant.first_name }} {{ participant.last_name }}</p>
                <p>Email: {{ participant.email }}</p>
                <p>Phone: {{ participant.phone }}</p>
                <p>Gender: {{ participant.gender }}</p>
                <v-alert
                  v-if="participant.pivot.remarks"
                  color="error"
                  text
                >
                  Remarks: {{ participant.pivot.remarks }}
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n';
import { mdiPlus } from '@mdi/js';

export default {
  props: {
    order: { type: Object, required: true },
  },

  setup() {
    return {
      t,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
