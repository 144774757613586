var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.shippingOrders},scopedSlots:_vm._u([{key:"item.tracking_number",fn:function(ref){
var item = ref.item;
return [(item.tracking_number != null)?_c('span',[_vm._v(_vm._s(item.tracking_number))]):_c('span',[_vm._v("-")])]}},{key:"item.tracking_url",fn:function(ref){
var item = ref.item;
return [(item.tracking_url != null)?_c('a',{attrs:{"href":item.tracking_url,"target":"_blank"}},[_vm._v("Click me")]):_c('span',[_vm._v("-")])]}},{key:"item.waybill_url",fn:function(ref){
var item = ref.item;
return [(item.waybill_url != null)?_c('a',{attrs:{"href":item.waybill_url,"download":""}},[_vm._v("Download")]):_c('span',[_vm._v("-")])]}},{key:"item.final_shipping_fee",fn:function(ref){
var item = ref.item;
return [(item.final_shipping_fee == null)?_c('div',[_vm._v("-")]):_c('div',[_vm._v(_vm._s(item.final_shipping_fee))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showActionMenu(item))?_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(item.channel == 'merchant')?_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTruck)+" ")]),_c('update-status',{attrs:{"shipment":item},on:{"updated":_vm.handleShippingStatusUpdated}})],1)],1):_vm._e(),(item.channel == 'gateway')?_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPhoneOutline)+" ")]),_c('span',[_c('v-btn',{attrs:{"depressed":"","dense":"","loading":_vm.sendingPickupRequest},on:{"click":function($event){return _vm.handlePickupRequest(item.id)}}},[_vm._v("Call Pickup")])],1)],1)],1):_vm._e(),(_vm.cancellable(item))?_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")]),_c('span',[_c('v-btn',{attrs:{"depressed":"","dense":"","loading":_vm.cancellingOrder},on:{"click":function($event){return _vm.handleCancelOrder(item.id)}}},[_vm._v(" Cancel ")])],1)],1)],1):_vm._e()],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }