import axios from '@axios'

const getShippingRates = async payload => {
  const endpoint = `/merchant/shipping-orders/shipping-rates`

  const { status, data } = await axios.post(endpoint, payload)

  return status == 200 ? data.data : null
}

const createOrder = async params => {
  const endpoint = `/merchant/shipping-orders`

  const { status, data } = await axios.post(endpoint, params)

  if (status == 201) {
    return data.data
  }

  return null
}

const requestPickup = async id => {
  const endpoint = `/merchant/shipping-orders/${id}/send-pickup-request`

  const { status } = await axios.post(endpoint)

  return status == 200
}

const cancelOrder = async id => {
  const endpoint = `/merchant/shipping-orders/${id}/cancel`

  const { status } = await axios.post(endpoint)

  return status == 200
}

const updateStatus = async (id, orderStatus) => {
  const endpoint = `/merchant/shipping-orders/${id}/update-status`

  const { status } = await axios.post(endpoint, {
    status: orderStatus,
  })

  return status == 200
}

export { getShippingRates, createOrder, requestPickup, cancelOrder, updateStatus }
